import http from "../http.js";

const Api = {
  /**电子合格证 */
  cateId: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "post",
      url: "/rjtProductCertificate/queryById",
      data: params,
    });
  },
  dict: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "post",
      url: "/common/getDict",
      data: params,
    });
  },
  subject: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "post",
      url: "/rjtSubjectInfo/queryById",
      data: params,
    });
  },
  showPlan: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "post",
      url: "/rjtSubjectShowPlan/getShowPlan",
      data: params,
    });
  },
  //追溯信息
  zhusxinx: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "post",
      // url: "/rjtProductCertificate/queryByPage",
      url: "/rjtCertificatePrint/queryByPage",
      data: params,
    });
  },
  zhusxq: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "post",
      url: "/rjtProductionRecord/queryById",
      data: params,
    });
  },
  //生产信息
  shengchanxinx: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "post",
      // url: "/rjtCertificatePrint/queryByPage",
      url: "/rjtProductionRecord/queryByPage",
      data: params,
    });
  },
  //监管信息
  jianguanxinx: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "post",
      url: "/rjtCertificatePrint/queryByPage",
      data: params,
    });
  },
  //检测信息
  jianche: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "post",
      url: "/rjtCheck/queryByPage",
      data: params,
    });
  },
  //编码
  bianm: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "get",
      url: `/QRCode/getByLabel/${params.labId}`,
    });
  },
  tickets: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "get",
      url: `/QRCode/verifyCode?code=${params.labId}${params.securityCode}`,
      // data: params,
    });
  },
  //编码查询
  chaxun: (params = {}) => {
    return http({
      apiDomainIndex: 2,
      method: "get",
      url: `/openapi/antifake/verify`,
      headers: {
        ticket: params,
      },
      // data: params,
    });
  },
  //获取承诺依据对应高亮信息
  taskAnd: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "get",
      url: `/QRCode/whetherTaskAndLog?productBatchId=${params.productBatchId}`,
    });
  },
  //记录扫描合格证二维码次数
  jilcs: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "post",
      url: `/rjtCertificateQuery/add`,
      data: params,
    });
  },
  //企业资质
  qiyeziz: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "post",
      url: `/rjtQualificateCert/queryByPage`,
      data: params,
    });
  },
  //种植信息
  getByBatchId: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      url: `/rjtSeeding/getByBatchId/${params.batchId}`,
      method: "get",
      // data: params,
    });
  },
  //施肥信息
  fertilizerBy: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      url: `/rjtApplyFertilizer/getByBatchId/${params.batchId}`,
      method: "get",
      // data: params,
    });
  },
  //收获信息
  byHarvest: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      url: `/rjtHarvest/getByBatchId/${params.batchId}`,
      method: "get",
      // data: params,
    });
  },
  //地块信息
  getBymassif: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      url: `/rjtParcel/getBy/${params.batchId}`,
      method: "get",
      // data: params,
    });
  },
  //实时监控
  byCamera: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      url: `/rjtAppCamera/camera/${params.batchId}`,
      method: "get",
      // data: params,
    });
  },
  cxparceloil: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      url: `/rjtParcel/parcelSoil/${params.batchId}`,
      method: "get",
      // data: params,
    });
  },
  pharmacyList: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      url: `/rjtPharmacy/findPage`,
      method: "post",
      data: params,
    });
  },
  byWeather: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      url: `/rjtParcelWheatherParameter/weather/${params.batchId}`,
      method: "get",
      // data: params,
    });
  },
  saplingBy: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      url: `/rjtSapling/getByBatchId/${params.batchId}`,
      method: "get",
      // data: params,
    });
  },
  byIrrigate: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      url: `/rjtIrrigate/getByBatchId/${params.batchId}`,
      method: "get",
      // data: params,
    });
  },
  byPosition: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      url: `/rjtGeographicPosition/getByBatchId/${params.batchId}`,
      method: "get",
      // data: params,
    });
  },
  cameraFlv: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      url: `/ezvizCamera/cameraFlv`,
      method: "post",
      data: params,
    });
  },
};

export default Api;
